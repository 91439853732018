import React, { useState } from "react";
import "./App.css";
import qp from "quoted-printable";
import parseMail from "./parser";
import Upload from "./components/Upload";
import Diff from "./components/Diff";
import MSGReader from "./msg/reader";
import { from } from "./helpers";
import { parsePDF } from "./pdf-parser";
import { CallOff } from "./types";

function readVolvoEMLFileBody(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const emailText = e.target?.result?.toString();
      if (!emailText) {
        reject("No text");
        return;
      }

      if (emailText.includes("<pre>")) {
        const decodedEmailText = qp.decode(emailText);
        const decodedEmailBody = decodedEmailText.split("<pre>")[1].split("</pre>")[0];
        resolve(decodedEmailBody);
      } else {
        resolve(from(emailText, "HEADER:", true));
      }
    };
    reader.onerror = reject;
    reader.onabort = reject;
    reader.readAsText(file, "ISO-8859-1");
  });
}

function readVolvoMSGFileBody(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const buffer = e.target?.result;
      const msgReader = new (MSGReader as any)(buffer);
      const fileData = msgReader.getFileData();
      if (fileData.error) return reject(fileData.error);
      else return resolve(fileData.body);
    };
    reader.onerror = reject;
    reader.onabort = reject;
    reader.readAsArrayBuffer(file);
  });
}

function readVolvoPDF(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const formData = new FormData();

    formData.append("pdf", file);
    const uploadRequest = fetch(process.env.REACT_APP_BACKEND_URL ?? "http://localhost:3000/parse-pdf", {
      method: "POST",
      body: formData,
    });

    uploadRequest
      .then((res) => res.json())
      .then(resolve)
      .catch(reject);
  });
}

function parseFile(file: File): Promise<CallOff> {
  const filename = file.name.toLowerCase();
  if (filename.endsWith("eml")) return readVolvoEMLFileBody(file).then(parseMail);
  if (filename.endsWith("msg")) return readVolvoMSGFileBody(file).then(parseMail);
  if (filename.endsWith("pdf")) return readVolvoPDF(file).then(parsePDF);
  throw Error("Unimplemented filetype");
}

function App() {
  const [callOffs, setCallOffs] = useState<CallOff[]>([]);
  const [error, setError] = useState<string | null>(null);

  const parseFiles = async (files: File[]) => {
    try {
      const callOffs = await Promise.all(files.map(parseFile));
      setCallOffs(callOffs);
    } catch (error) {
      setError(error + "");
    }
  };

  return (
    <div className="App">
      <div style={{ color: "red" }}>
        {error}
        {error ? ". Please contact the site administrator." : ""}
      </div>
      <Upload parseFiles={parseFiles} />
      <Diff parseResults={callOffs} />
      <div
        style={{
          position: "absolute",
          top: "1em",
          left: "1em",
          display: "flex",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <div className="over-30-days legend-box" />
          <div className="under-30-days legend-box" />
          <div className="in-the-past legend-box" />
        </div>

        <div
          style={{
            paddingLeft: "1em",
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            alignItems: "start",
          }}
        >
          <div>&gt; 30 days from today</div>
          <div>&lt; 30 days from today</div>
          <div>Delivery date passed</div>
        </div>
      </div>
    </div>
  );
}

export default App;
