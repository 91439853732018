import { from, trimmedString } from "./helpers";
import { CallOff, Delivery, LastDelivery, Metadata, Product } from "./types";

class ParseError extends Error {}

const newlineRegex = /\r?\n/;

const getSubsectionLimiter = (x: string[]) => x.find((x) => x.startsWith("------"));

function parseProductHeaderTabs(productHeader: string) {
  /** Header example:
   * PART.NO  : 31684295                           		ORD.NO      : 745931554174
   * PREVIOUS : 20211221032721                     		QTY CUMULAT : 195
   * CALC.DATE: 2021-12-21
   */

  const headerEntries = productHeader
    .trim()
    .split(newlineRegex)
    .map((x) => x.split("\t"))
    .flat()
    .filter((x) => x.length);

  const product: any = {};

  for (const entry of headerEntries) {
    const [key, value] = entry.split(":");
    product[key.trim()] = value.trim();
  }
  return product;
}

function parseProductHeader(productHeader: string) {
  const headerEntries = productHeader
    .split(newlineRegex)
    .flatMap((x) => x.split("  "))
    .filter((x) => x)
    .flatMap((x) => x.split(":"))
    .map((x) => x.trim())
    .filter((x) => x);
  return {
    "PART.NO": headerEntries[1],
    "CALC.DATE": headerEntries[9],
    "ORD.NO": headerEntries[3],
    PREVIOUS: headerEntries[5],
    "QTY CUMULAT": headerEntries[7],
  };
}

function parseProductLastDeliveries(lastDeliveries: string): LastDelivery[] {
  const headerEntries = lastDeliveries
    .trim()
    .split(newlineRegex)
    .filter((x) => x.trim())
    .slice(1)
    .map((x) => x.split("  "))
    .map((x) => x.map((y) => y.trim()))
    .map((x) => x.filter((y) => y));

  return headerEntries.map(([d, q, r, n]) => ({
    date: d,
    quantity: q,
    received: r,
    deliveryNote: n,
  }));
}

function parseProductLastDeliveriesTabs(lastDeliveries: string): LastDelivery[] {
  /**
   * LAST DELIVERIES		  QUANTITY	  RECEIVED	DELIVERY NOTE
   * 20211011		         1	         1	    232434
   * 20210927		         1	         1	    230779
   * 20210601		         1	         1	    218337
   */
  const lastDeliveriesEntries = lastDeliveries
    .trim()
    .split(newlineRegex)
    .filter((x) => x.trim())
    .slice(1) // Need to remove the 1st index as there is a double tab in the table
    .map((x) => x.split("\t").filter((_, i) => i !== 1));

  return lastDeliveriesEntries.map(([d, q, r, n]) => ({
    date: trimmedString(d),
    quantity: trimmedString(q),
    received: trimmedString(r),
    deliveryNote: trimmedString(n),
  }));
}

function parseProductDeliveriesTabs(deliveries: string): Delivery[] {
  /**
   * DELIVERIES	TIME	REASON	STATUS	  QUANTITY	ORDER NO
   * 2021-12-21		   3 	   1 	         8
   * 2022-03-21	08:00		   4	         2
   * 2022-03-28	08:00		   4	         6
   */
  const deliveriesEntries = deliveries
    .trim()
    .split(newlineRegex)
    .filter((x) => x.trim())
    .slice(1)
    .map((x) => x.split("\t"));

  return deliveriesEntries.map(([d, t, r, s, q, o]) => ({
    date: trimmedString(d),
    time: trimmedString(t),
    reason: trimmedString(r),
    status: trimmedString(s),
    quantity: trimmedString(q),
    orderNo: trimmedString(o),
  }));
}
function parseProductDeliveries(deliveries: string): Delivery[] {
  const lines = deliveries
    .trim()
    .split(newlineRegex)
    .filter((x) => x.trim());
  const header = lines[0];
  const indices: number[] = [0];
  for (let i = 1; i < header.length - 2; i++) {
    if (header[i - 1] === " " && header[i] === " " && header[i + 1] !== " ") {
      indices.push(i);
    }
  }
  indices.push(header.length);

  const deliveriesEntries = lines
    .slice(1)
    .map((x) => indices.slice(0, -1).map((start, i) => x.substring(start, indices[i + 1])))
    .map((x) => x.map((y) => y.trim()));
  return deliveriesEntries.map(([d, t, r, s, q, o]) => ({
    date: trimmedString(d),
    time: trimmedString(t),
    reason: trimmedString(r),
    status: trimmedString(s),
    quantity: trimmedString(q),
    orderNo: trimmedString(o),
  }));
}

function parseProduct(productSection: string, tabs: boolean): Product {
  const subSectionLimiter = getSubsectionLimiter(productSection.split(newlineRegex));
  if (!subSectionLimiter) throw new ParseError("Could ont find subsection limiter in document");
  const subSections = productSection.split(subSectionLimiter);
  if (subSections.length === 0 || subSections.length >= 4) throw new ParseError("Invalid product entry");

  const product: Product = tabs ? parseProductHeaderTabs(subSections[0]) : parseProductHeader(subSections[0]);

  if (subSections.length === 2) {
    const subSection = subSections[1];
    if (subSection.startsWith("\r\nLAST DELIVERIES")) {
      product["LAST DELIVERIES"] = tabs
        ? parseProductLastDeliveriesTabs(subSection)
        : parseProductLastDeliveries(subSection);
      product["DELIVERIES"] = [];
    } else if (subSection.startsWith("\r\nDELIVERIES") || subSection.startsWith("\r\nDELIVERY")) {
      product["LAST DELIVERIES"] = [];
      product["DELIVERIES"] = tabs ? parseProductDeliveriesTabs(subSection) : parseProductDeliveries(subSection);
    } else {
      throw new ParseError("Unimplemented section");
    }
  } else {
    product["LAST DELIVERIES"] = tabs
      ? parseProductLastDeliveriesTabs(subSections[1])
      : parseProductLastDeliveries(subSections[1]);
    product["DELIVERIES"] = tabs ? parseProductDeliveriesTabs(subSections[2]) : parseProductDeliveries(subSections[2]);
  }

  return product;
}

function parseMetadataTabs(text: string): Metadata {
  /**
   *
   * BP2TU:order@wiretronic.com:F01:-AEPT1
   * -----------------------------------------------------------------------------------------(1)
   * VOLVO CARS PILOT PLANT GOTEBORG                         SCHED : 20211221032721
   *                                                         DATE  : 2021-12-21
   * SUPPPLIER: AEPT1 WIRETRONIC AB                          VOLVO : BP2TU
   * -----------------------------------------------------------------------------------------
   * SCHEDULE CHANGES FROM DATE : 2021-12-21
   * -----------------------------------------------------------------------------------------
   * PARTYNAME: VOLVO CARS PILOT PLANT GOTEBORG              INTERNAL ID : BP2TU
   * DEST.    :     PVOL                                     DELIVERY    : 173
   */

  const subsectionLimiter = getSubsectionLimiter(text.split(newlineRegex).slice(4));
  const lines = text
    .trim()
    .split(newlineRegex)
    .filter((x) => x)
    .slice(2)
    .filter((line) => line !== subsectionLimiter)
    .map((x) => x.split("\t"))
    .flat()
    .map((x) => x.trim())
    .filter((x) => x.trim().length)
    .map((x) => from(x, ":"))
    .map((x) => x.trim());
  return {
    origin: lines[0],
    sched: lines[1],
    date: lines[2],
    supplier: lines[3],
    volvo: lines[4],
    scheduleChangesFrom: lines[5],
    partyName: lines[6],
    internalId: lines[7],
    dest: lines[8],
    delivery: lines[9],
  };
}

function parseMetadata(text: string): Metadata {
  const subsectionLimiter = getSubsectionLimiter(text.split(newlineRegex).slice(4));
  const lines = text
    .trim()
    .split(newlineRegex)
    .slice(2)
    .filter((line) => line !== subsectionLimiter)
    .map((x) => x.trim())
    .filter((x) => x)
    .flatMap((x) => x.split("      "))
    .map((x) => x.trim())
    .filter((x) => x.trim().length)
    .map((x) => from(x, ":"))
    .map((x) => x.trim());
  return {
    origin: lines[0],
    sched: lines[1],
    date: lines[2],
    supplier: lines[3],
    volvo: lines[4],
    scheduleChangesFrom: lines[5],
    partyName: lines[6],
    internalId: lines[7],
    dest: lines[8],
    delivery: lines[9],
  };
}

function parseCallOffSection(callOffSection: string, tabs: boolean): CallOff {
  const sectionLimiter = callOffSection.split(newlineRegex).find((x) => x.startsWith("===="));
  if (!sectionLimiter) throw new ParseError('Document does not contain "=====..." section');
  const sections = callOffSection.split(sectionLimiter);
  const metadata = tabs ? parseMetadataTabs(sections[0]) : parseMetadata(sections[0]);
  const products = sections.slice(1).map((x) => parseProduct(x, tabs));
  return { metadata, products };
}

/**
 * Brittle statemachine parser for volvo call-off contents
 * Accepts the body of emails, enocded as utf8
 */
export default function parseMail(mail: string): CallOff {
  try {
    const ind = mail.indexOf("This email message (including its attachments)");
    const callOffs = mail
      .slice(0, ind === -1 ? mail.length : ind)
      .split("HEADER:")
      .map((x) => x.trim())
      .filter((x) => x)
      .map((x) => parseCallOffSection(x, /\t/.test(mail)));

    return {
      metadata: callOffs[0].metadata,
      products: callOffs.flatMap((x) => x.products),
    };
  } catch (e) {
    console.error(e);
    throw e;
  }
}
