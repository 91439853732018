import { from } from "./helpers";
import { CallOff, Delivery, Metadata, Product } from "./types";

/**
 * Last deliveries  line starts with `LAST DELIVERIES` and ends with `DELIVERY NOTE`.
 * Exact number of whitespace inbetween `["LAST DELIVERIES", "QUANTITY", "RECEIVED", "DELIVERY NOTE"]`
 * isn't fixed
 */
function getLastDeliveriesHeaderLine(text: string) {
  return text.slice(text.indexOf("LAST DELIVERIES"), text.indexOf("DELIVERY NOTE") + "DELIVERY NOTE".length) + "\n";
}

/**
 * Deliveries  line starts with `DELIVERIES` and ends with `ORER NO`.
 * Exact number of whitespace inbetween `["DELIVERIES", "TIME", "STATUS", "REASON", "QUANTITY", "ORDER NO"]`
 * isn't fixed. Assumes that the LAST DELIVERIES line isn't present in the `text` argument
 */
function getDeliveriesHeaderLine(text: string) {
  return text.slice(text.indexOf("DELIVERIES"), text.indexOf("ORDER NO") + "ORDER NO".length) + "\n";
}

function parseDeliveryLine(x: string): Delivery {
  // Example x
  // 2022-08-15                08:00           3                    1         300,00   134931554174
  // 1-11, 27-31, 40-46, 62-67, 71-78, 83-90

  const parts = x
    .trim()
    .split(" ")
    .filter((x) => x);
  const partStartCols = parts.reduce<number[]>(
    (prev, current) => [...prev, x.indexOf(current, prev[prev.length - 1])],
    []
  );

  return {
    date: parts[0],
    time: parts.filter((x) => x.includes(":"))[0] ?? "",
    status: parts.filter((x, i) => partStartCols[i] >= 40 && partStartCols[i] <= 46)[0] ?? "",
    reason: parts.filter((x, i) => partStartCols[i] >= 62 && partStartCols[i] <= 68)[0] ?? "",
    quantity: parts.filter((x) => x.includes(","))[0] ?? "",
    orderNo: parts[parts.length - 1],
  };
}

/**
 * Rightmost column containing orederno is sometimes misaligned.
 * Ues heuristic to fix it
 */
function fixMisalignedDeliveryLines(deliveryLines: string[]): string[] {
  const maxLength = Math.max(...deliveryLines.map((l) => l.length));
  const isMisaligned = [false, ...deliveryLines.slice(1).map((_, i) => maxLength - deliveryLines[i].length > 5)];

  return deliveryLines.reduce<string[]>((prev, current, i) => {
    if (isMisaligned[i]) {
      prev[prev.length - 1] += "  " + current.trim();
    } else {
      prev.push(current);
    }
    return prev;
  }, []);
}

function parsePDFSection(pdfSection: string): Product {
  const [preambleText, rem1] = pdfSection.split(getLastDeliveriesHeaderLine(pdfSection));
  const [lastDeliveriesText, rem2] = rem1.split(getDeliveriesHeaderLine(rem1));

  const preambleLines = preambleText.split("\n").filter((x) => x);
  const [partNoBlock, ordNoBlock] = preambleLines[0]
    .trim()
    .split(" ")
    .filter((x) => x);
  const previousBlock = preambleLines[1].split(" ")[1];
  const [, calcDateBlock, , , qtyCumulatBlock] = preambleLines[2].split(" ").filter((x) => x);

  const lastDeliveries = lastDeliveriesText
    .split("\n")
    .filter((x) => x)
    .map((line) => line.split(" ").filter((x) => x))
    .map(([date, quantity, received, deliveryNote]) => ({
      date,
      quantity,
      received,
      deliveryNote,
    }));

  const deliveries = fixMisalignedDeliveryLines(rem2.trim().split("\n")).map(parseDeliveryLine);

  return {
    "CALC.DATE": calcDateBlock.trim(),
    "LAST DELIVERIES": lastDeliveries,
    "ORD.NO": from(ordNoBlock, ":").trim(),
    "PART.NO": partNoBlock.trim(),
    "QTY CUMULAT": qtyCumulatBlock.trim(),
    DELIVERIES: deliveries,
    PREVIOUS: previousBlock.trim(),
  };
}

function parseHeader(header: string): Metadata {
  const relevantLines = header
    .trim()
    .split("\n")
    .slice(2)
    .filter((x) => x);
  const origin = relevantLines[0].split("    ")[0].trim();
  const sched = relevantLines[0].split(" ").slice(-1)[0].trim();
  const date = relevantLines[1].split(" ").slice(-1)[0].trim();
  const supplier = relevantLines[2].split(" ")[1].trim();
  const volvo = relevantLines[2].split(" ").slice(-1)[0].trim();
  const scheduleChangesFrom = relevantLines[3].split(" ")[4].trim();
  const partyName = relevantLines[4].split(":")[1].split("  ")[0].trim();
  const internalId = relevantLines[4].split(" ").slice(-1)[0].trim();
  const dest = relevantLines[5].split(" ")[1].trim();

  return {
    origin,
    sched,
    date,
    supplier,
    volvo,
    scheduleChangesFrom,
    partyName,
    internalId,
    dest,
    delivery: "",
  };
}

/**
  Remove lines inbetween "HEADER:" To "DEST.:" including
 */
function removeHeaders(pdfContent: string): string {
  let keep = true;

  return pdfContent
    .split("\n")
    .filter((v) => {
      if (v.startsWith("HEADER:")) {
        keep = false;
      }
      if (v.startsWith("DEST.:")) {
        keep = true;
        return false;
      }

      return keep;
    })
    .join("\n");
}

export function parsePDF(pdfContent: string): CallOff {
  const whoisthis = "";
  const cleanedContent = pdfContent.replaceAll(whoisthis, "");

  const firstSectionIndex = cleanedContent.indexOf("PART.NO");

  if (firstSectionIndex === -1) throw new Error("Could not find first section.");

  const header = cleanedContent.slice(0, firstSectionIndex).trim();

  const contentWithoutHeaders = removeHeaders(cleanedContent).trim();
  const sections = contentWithoutHeaders.split("PART.NO:").slice(1);
  const products = sections.map(parsePDFSection);

  return {
    metadata: parseHeader(header),
    products: products,
  };
}
