import React from "react";
import { allSame, compareDateStrings, daysBetween, push, unique } from "../helpers";
import { CallOff } from "../types";

interface DiffProps {
  parseResults: CallOff[];
}
interface RequestedDelivery {
  issuedDate: string;
  date: string;
  quantity: string;
}
type CallOffAuth = number | "missing" | "passed";

function buildProductTable(
  deliveries: RequestedDelivery[],
  issueDates: string[],
  deliveryDates: string[]
): CallOffAuth[][] {
  const tableData: CallOffAuth[][] = [];

  for (const deliveryDate of deliveryDates) {
    const row: CallOffAuth[] = [];
    for (const issueDate of issueDates) {
      const del = deliveries.find((x) => x.issuedDate === issueDate && x.date === deliveryDate);
      if (del !== undefined) {
        row.push(parseInt(del.quantity));
      } else if (compareDateStrings(issueDate, deliveryDate) >= 0) {
        row.push("passed");
      } else {
        row.push("missing");
      }
    }
    tableData.push(row);
  }

  return tableData;
}

function hasMissingEntry(row: CallOffAuth[]): boolean {
  return row.includes("missing");
}

function hasNonZeroEntry(row: CallOffAuth[]): boolean {
  return row.filter((x): x is number => typeof x === "number").filter((x) => x > 0).length > 0;
}

function hasMissingAndNonZeroEntry(row: CallOffAuth[]): boolean {
  return hasMissingEntry(row) && hasNonZeroEntry(row);
}

function hasChangedQuantity(row: CallOffAuth[]): boolean {
  const quantities = row.filter((x): x is number => typeof x === "number");
  return !allSame(quantities); // TODO bug when only one entry and that entry is zero?
}

function filterRows(deliveryDates: string[], tableRows: CallOffAuth[][]): [string[], CallOffAuth[][]] {
  const filteredDeliveryDates: string[] = [];
  const filteredTablerows: CallOffAuth[][] = [];

  for (let i = 0; i < deliveryDates.length; i++) {
    if (hasMissingAndNonZeroEntry(tableRows[i]) || hasChangedQuantity(tableRows[i])) {
      filteredDeliveryDates.push(deliveryDates[i]);
      filteredTablerows.push(tableRows[i]);
    }
  }

  return [filteredDeliveryDates, filteredTablerows];
}

function displayProduct(productNo: string, deliveries: RequestedDelivery[], issueDates: string[]) {
  const deliveryDates = unique(deliveries.map((x) => x.date));
  deliveryDates.sort(compareDateStrings);

  const tableData = buildProductTable(deliveries, issueDates, deliveryDates);

  const [filteredDeliveryDates, filteredTableData] = filterRows(deliveryDates, tableData);

  if (filteredDeliveryDates.length === 0) return <></>;

  const tableRows = [];
  for (let i = 0; i < filteredDeliveryDates.length; i++) {
    const deliveryDate = filteredDeliveryDates[i];
    const row = [<th>{deliveryDate}</th>];

    const daysBetweenDeliveryDateAndNow = daysBetween(new Date(deliveryDate));
    let tdClass = "in-the-past";
    if (daysBetweenDeliveryDateAndNow > 30) tdClass = "over-30-days";
    else if (daysBetweenDeliveryDateAndNow > 0) tdClass = "under-30-days";

    for (let j = 0; j < issueDates.length; j++) {
      const v = filteredTableData[i][j];
      row.push(<td className={tdClass}>{typeof v === "number" ? v : ""}</td>);
    }
    tableRows.push(row);
  }
  tableRows[0].unshift(<th rowSpan={deliveryDates.length}>Delivery date</th>);
  return (
    <div
      style={{
        padding: "1em",
      }}
    >
      <table>
        <thead>
          <tr>
            <th colSpan={2} rowSpan={2}>
              PART.NO: {productNo}
            </th>
            <th colSpan={issueDates.length}>Issue date</th>
          </tr>
          <tr>
            {issueDates.map((date) => (
              <th>{date}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableRows.map((row) => (
            <tr>{row}</tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function aggregateDeliveriesByProduct(callOffs: CallOff[]): {
  [productNo: string]: RequestedDelivery[];
} {
  const productsData: { [productNo: string]: RequestedDelivery[] } = {};

  for (const callOff of callOffs) {
    for (const product of callOff.products) {
      const deliveries = product.DELIVERIES.map((delivery) => ({
        ...delivery,
        issuedDate: callOff.metadata.date,
      }));

      for (const delivery of combineSameDayDeliveries(deliveries)) {
        push(productsData, product["PART.NO"], delivery);
      }
    }
  }
  return productsData;
}

function combineSameDayDeliveries(deliveries: RequestedDelivery[]): RequestedDelivery[] {
  const deliveryDates = unique(deliveries.map((delivery) => delivery.date));
  return deliveryDates
    .map((x) => deliveries.filter((y) => y.date === x))
    .map((sameDayDeliveries) =>
      sameDayDeliveries.reduce((a, b) => ({
        ...a,
        quantity: (parseInt(a.quantity) + parseInt(b.quantity)).toString(),
      }))
    );
}

function displayVCCUnit(callOffs: CallOff[]) {
  if (callOffs.length === 1)
    return (
      <div className="table-column">
        {callOffs[0].metadata.volvo}
        <p>Only one document, nothing to compare to</p>
      </div>
    );

  const productsData = aggregateDeliveriesByProduct(callOffs);
  const issueDates = callOffs.map((callOff) => callOff.metadata.date);
  issueDates.sort(compareDateStrings);

  return (
    <div className="table-column">
      {callOffs[0].metadata.volvo}
      <div style={{ display: "flex", flexDirection: "column" }}>
        {Object.entries(productsData).map(([productNo, deliveries]) =>
          displayProduct(productNo, deliveries, issueDates)
        )}
      </div>
    </div>
  );
}
function aggregateCallOffsByUnit(callOffs: CallOff[]): CallOff[][] {
  const vccUnits = unique(callOffs.map((callOff) => callOff.metadata.volvo));
  const callOffsAggregatedByUnit = vccUnits.map((x) => callOffs.filter((callOff) => callOff.metadata.volvo === x));
  return callOffsAggregatedByUnit;
}

export default function Diff({ parseResults }: DiffProps) {
  const callOffsAggregatedByUnit = aggregateCallOffsByUnit(parseResults);
  callOffsAggregatedByUnit.sort((a, b) => a[0].metadata.volvo.localeCompare(b[0].metadata.volvo));

  return <div style={{ display: "flex", flexDirection: "row" }}>{callOffsAggregatedByUnit.map(displayVCCUnit)}</div>;
}
