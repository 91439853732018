export function daysBetween(date: Date) {
  const oneDay = 24 * 60 * 60 * 1000;
  const today = new Date();
  return Math.round((date.getTime() - today.getTime()) / oneDay);
}

export function push<T>(dict: { [key: string]: T[] }, key: string, element: T) {
  if (key in dict) dict[key].push(element);
  else dict[key] = [element];
}

export function unique<T>(x: T[]): T[] {
  return Array.from(new Set(x));
}

export const compareDateStrings = (a: string, b: string) => new Date(a).getTime() - new Date(b).getTime();

export const trimmedString = (xx: string | undefined) => (xx ?? "").trim();

export const from = (x: string, y: string, inclusive = false) => x.substring(x.indexOf(y) + (inclusive ? 0 : 1));

export const allSame = <T>(arr: T[]) => arr.every((x) => x === arr[0]);
