import React, { useState } from "react";

export interface UploadProps {
  parseFiles: (x: File[]) => void;
}

function displayFiles(fileNames: string | string[] | null) {
  if (!fileNames) return "";
  else if (fileNames instanceof Array)
    return fileNames.length > 1 ? "Selected files " + fileNames.join(", ") : "Selected file " + fileNames[0];
  else return fileNames;
}

export default function Upload({ parseFiles }: UploadProps) {
  const [fileNames, setFileNames] = useState<string[] | string | null>(null);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target && e.target.files && e.target.files.length) {
      const files = [];
      for (let i = 0; i < e.target.files.length; i++) {
        files.push(e.target.files[i]);
      }

      setFileNames(files.map((x) => x.name));
      parseFiles(files);
    } else {
      setFileNames("No files selected");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        padding: "1em",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          maxWidth: "50em",
        }}
      >
        <p>{fileNames === null ? "To begin, select multiple files" : displayFiles(fileNames)}</p>
        <button style={{ width: "100px" }}>
          <label htmlFor="file-upload">Select files</label>
        </button>
        <input
          id="file-upload"
          style={{
            opacity: 0,
            width: 0,
          }}
          type="file"
          multiple
          onChange={onChange}
        />
      </div>
    </div>
  );
}
